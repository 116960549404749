//
// reboot.scss
//

button,
a {
  outline: none !important;
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
}

.apexcharts-toolbar {
  display: none !important;
}

// Forms

label {
  font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: $font-weight-bold;
}
