//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.clear-background {
  background: none !important;
}

.clear-border {
  border: none;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
